// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import "bootstrap"
import "../src/application.scss"

//以下の名前はenvironment.js(webpack.config.js)でalias設定が必要
require('jquery')
require("jquery-ui")
require("jquery-css/all.css")
require("jquery-ui2/i18n/datepicker-ja.js")
//require("jquery-themes/overcast/theme.css")
//require("jquery-themes/smoothness/theme.css")

import "packs/jquery_bunch"

//const images = require.context("../images", true)
//const imagePath = name => images(name, true)


// == datepicker ==
// id指定するとき
//$(function() {
//  $("#datepicker").datepicker({
//  });
//});

$(document).ready(function(){
  $('input.datepicker').datepicker({
    dateFormat: 'yy-mm-dd',
  });
});

// == autocomplete ==
// item_idのsearchは文字追加時の食い違いが出るので止める
$(function() {
  // 入力補完を実施する要素に単語リストを設定
  $("#inputItemName").autocomplete({
    source: '/front/item_search.json',
    autoFocus: true,
    minLength: 1,
    delay: 300,
  });
});

// 日本語入力 Type A >>
// 日本語入力をスタートしたら無効化
$('#inputItemName').on('compositionstart', function(){$('#inputItemName').autocomplete('disable');});
// 日本語入力が確定したら有効化
$('#inputItemName').on('compositionend', function(){$('#inputItemName').autocomplete('enable');});
// << Type A

// 日本語入力 easy autocomplete用 Type B >>
// 以下を追加
//$inputItemName.on('compositionend', function () {
//  let evt = jQuery.Event("keyup");
//  evt.keyCode = 41;
//  $inputItemName.trigger(evt);
//});
// << Type B
